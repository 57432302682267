.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (min-width: 932px) {

  /* App.css */
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Align items at the start of the container */
    height: 100vh;
  }

  .welcome-message {
    margin-top: 40px;
    /* Add margin to push the message to the top */
    color: "#30A46C";
  }

  .center-container {
    text-align: center;
    margin-top: 50px;
    /* Add margin to center the content vertically */
  }

  .options {
    margin-top: 20px;
  }

  .option {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background-color: #30A46C;
    /* Button background color */
    color: #fff;
    /* Button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 600;
    font-size: 16px;
  }

  .option:hover {
    background-color: #30A46C;
    /* Change the hover color */
    color: #fff;
    /* Change the text color on hover */
    border-radius: 50px;
    /* width: 50px; */
  }
  .fade-in {
    animation: fadeIn 1s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .enquiry-text{
    color: #000;
    font-size: 1.2vw;
    margin: 10px;
    width: 50vw;
    margin-top: 40px;
  }
  .redText {
    color: red;
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .App-header {
    /* background-color: #30A46C; */
    /* background-color: #d6dbe3; */
    min-height: auto;
    display: flex;
    flex-direction: row;
    /* margin-right: 250px; */
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-inner-container {
    width: 300px;
    height: 700px;
    position: relative;
    background-color: #30a46c;
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
    margin-left: 300px;
    margin-top: -700px;
  }

  .App-inner-container-for-book-page {
    width: 150px;
    height: 725px;
    position: absolute;
    background-color: #ffffff;
    /* border-bottom-left-radius: 14px;
    border-top-left-radius: 14px; */
    /* margin-left: 400px; */
    margin-top: 20px;
  }

  .App-semi-container {
    width: 700px;
    height: 700px;
    position: relative;
    background-color: #ffffff;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    margin-left: 600px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .App-semi-container-for-book-page {
    width: 700px;
    height: 725px;
    position: absolute;
    background-color: #ffffff;
    /* border-top-right-radius: 14px;
    border-bottom-right-radius: 14px; */
    /* margin-left: 00px; */
    align-items: center;
    justify-content: start;
    margin-top: 20px;
  }

  .day-field-align-same {
    margin-left: -7px;
    width: 30px;
    height: 20px;
    margin-top: 13px;
    position: absolute;
  }

  .day-field-align-same.delhi{
    margin-left: 0px;
    width: 30px;
    height: 20px;
    margin-top: 13px;
    position: absolute;
  }

  .edit-icon-style {
    margin-top: 47px;
    margin-left: 225px;
    margin-bottom: 10px;
    position: absolute;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  .icon-style {
    width: 12px;
    height: 15px;
    margin-top: 3px;
    margin-left: 5px;
  }

  .edit-lable-style {
    color: #0c53b7;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    text-decoration: underline;
    margin-top: 5px;
    margin-left: -30px;
  }

  .day-field-align-next {
    margin-left: 13px;
    width: 30px;
    height: 20px;
    margin-top: 14px;
    position: absolute;
  }

  .day-field-label-same {
    width: 70px;
    margin-left: 10px;
    margin-top: 12px;
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    line-height: 1rem;
  }

  .day-field-label-next {
    width: 70px;
    margin-left: 33px;
    margin-top: 12px;
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    line-height: 1rem;
  }

  .delivery-immediate {
    font-size: 11px;
    color: #30A46C;
    padding: 5px;
    margin-left: 51px;
  }

  .delivery-6-hours {
    font-size: 11px;
    color: #30A46C;
    padding: 5px;
    margin-left: 25px;
  }

  .shop-details {
    padding-top: 270px;
  }

  .shop-detail-style {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }

  .shop-sub-detail-style {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #fafafa;
  }

  .form-container {
    width: 630px;
    height: 640px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 35px;
    margin-top: 40px;
    border-radius: 14px;
    box-shadow: 5px 10px 8px 10px #a1e3c3;
  }

  .pickup-style {
    width: 200px;
    height: 25px;
    margin: 15px;
    padding-left: 10px;
    border-radius: 14px;
    box-shadow: 5px 10px 8px 10px #a1e3c3;
  }

  .form-container-for-book-page {
    width: 670px;
    height: 630px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: -160px;
    /* margin-top: 40px; */
    border-radius: 14px;
    box-shadow: 5px 10px 8px 10px #a1e3c3;
    padding-left: 50px;
  }

  /* .form-contn {
    width: 200px;
    display: flex;
    flex-direction: row;
    flex-flow: column;
    position: absolute;
  } */


  .form-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    padding: 15px;
    margin-top: 20px;
    margin-left: 100px;
  }

  .day-field-align {
    margin-left: -50px;
    width: 120px;
    height: 25px;
    margin-top: 8px;
    margin-left: 2px;
  }

  .day-field-label {
    width: 70px;
    margin-left: -50px;
    margin-top: 12px;
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    line-height: 1rem;
  }

  .web-view-style-one {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .web-view-style-three {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    margin-top: 20px;
  }

  .web-line-style {
    display: flex;
    justify-content: center;
    width: 400px;
    height: 5px;
    /* background-color: #858181; */
    /* margin-top: 16px; */
  }

  .web-view-style-two {
    display: flex;
    flex-direction: row;
    /* margin-left: 40px; */
  }

  .web-view-style-time {
    margin-left: 75px;
    position: absolute;
    margin-top: -30px;
  }

  .web-view-style-time-from {
    margin-left: -38px;
    position: absolute;
    margin-top: -5px;
  }

  .web-view-style-time-to {
    margin-left: 10px;
    position: absolute;
    margin-top: -5px;
  }

  .web-view-style-challan {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
  }

  .web-view-style-challan {
    display: flex;
    flex-direction: column;
    margin-top: 1px;
  }

  .web-view-style-2-wheeler {
    width: 80px;
    margin-left: 5px;
    margin-top: 45px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .web-view-style-3-wheeler {
    width: 100px;
    margin-left: 10px;
    margin-top: 34px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .web-view-style-pickup {
    width: 80px;
    margin-left: 25px;
    margin-top: 43px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    /* Adjust the blur amount as needed */
    z-index: 999;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: #000000;
    font-size: 14px;
    width: 200px;
  }

  .modal button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .subtitle-style-map {
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    /* margin-top: 5px; */
    margin-left: 7px;
  }

  .payment-failed-button-align {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-left: 200px;
  }

  .paysuccess-alignment {
    font-size: 6px;
    margin-left: 1000px;
    margin-right: 60px;
  }

  .two-wheeler-img-style {
    width: 25px;
    height: 25px;
    margin-top: 8px;
    margin-bottom: -20px;
    margin-left: 40px;
    position: absolute;
  }

  .three-wheeler-img-style {
    width: 25px;
    height: 23px;
    margin-top: 10px;
    margin-bottom: -20px;
    margin-left: 50px;
    position: absolute;
  }

  .four-wheeler-img-style {
    width: 30px;
    height: 25px;
    margin-top: 10px;
    margin-bottom: -20px;
    margin-left: 55px;
    position: absolute;
  }

  .button-style-disable {
    width: 180px;
    height: 40px;
    background-color: #83dbb0;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    margin-left: 240px;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .verify-button-disable {
    width: 120px;
    height: 30px;
    padding-top: 10px;
    margin-left: 240px;
    margin-top: 20px;
    background-color: #83dbb0;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .my-order-button {
    width: 85px;
    height: 25px;
    background-color: #30a46c;
    border-radius: 10px;
    padding-top: 8px;
    margin-top: 22px;
    margin-left: 543px;
    margin-right: -110px;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
  }

  .my-order-button-shop {
    width: 85px;
    height: 25px;
    background-color: #30a46c;
    border-radius: 10px;
    padding-top: 8px;
    margin-top: 20px;
    margin-left: 530px;
    margin-right: -110px;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
  }

  .button-style {
    width: 210px;
    height: 30px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    margin-top: 10px;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .button-style-ok {
    width: 120px;
    height: 25px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    margin-top: 15px;
    margin-right: 7px;
    margin-left: 290px;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    padding-top: 8px;
  }

  .button-style-main {
    width: 170px;
    height: 40px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    margin-top: -65px;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .map-outer-container {
    color: #000000;
    margin-left: 30;
    font-size: 20;
    margin-top: 20;
    background-color: #fafafa;
    position: fixed;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    /* width: 50vw;
    height: 120vh; */
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    margin-top: 0px;
    top: 0;
    left: 0;
    align-items: center;
  }

  .map-container {
    width: 68vw;
    height: 79vh;
    top: 10px;
    left: 133px;
    position: absolute;
  }

  .form-display-map {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: 600px;
  }

  .form-field-style-search {
    width: 300px;
    height: 30px;
    margin-left: -85px;
    font-size: 13px;
    margin-top: 87px;
  }

  .form-container-map {
    width: 620px;
    height: 265px;
    background-color: #f5f7f9;
    position: fixed;
    align-items: center;
    margin-left: 1475px;
    margin-top: 335px;
    border-radius: 14px;
    box-shadow: 1px 1px 2px 3px #a1e3c3;
  }

  .otp-verification-container {
    width: 600px;
    height: 520px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 300px;
    margin-top: 650px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
    scroll-behavior: smooth;
  }

  .verify-button {
    width: 120px;
    height: 30px;
    padding-top: 10px;
    margin-left: 240px;
    margin-top: 20px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .verify-button-order {
    width: 160px;
    height: 30px;
    padding-top: 10px;
    margin-left: 220px;
    margin-top: 120px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .order-history-container {
    width: 705px;
    height: 680px;
    background-color: #f5f7f9;
    position: relative;
    margin-left: 416px;
    margin-top: 50px !important;
    /* align-items: center;
    justify-content: center; */
    overflow: auto;
    /* Add this line to enable scrolling */
    scroll-behavior: smooth;
  }

  .list-container {
    width: 590px;
    height: 120px;
    background-color: #ffffff;
    position: relative;
    margin-left: 55px;
    margin-top: 50px;
    cursor: pointer;
    overflow: auto;
    margin-bottom: 10px;
    /* Add this line to enable scrolling */
    scroll-behavior: smooth;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
    border-radius: 14px;
  }

  .booking-id-style {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    position: absolute;
    margin-left: 30px;
    color: #000000;
  }

  .iconStyle {
    margin-left: -90px;
  }

  .time-text-style {
    color: #000000;
    margin-left: -20px;
    font-size: 14px;
    margin-top: 16px;
  }

  .trip-status {
    color: #000000;
    margin-left: 422px;
    font-size: 14px;
    margin-top: 16px;
    text-align: left;
  }

  .payment-status {
    color: #000000;
    margin-left: 330px;
    font-size: 14px;
    margin-top: -20px;
    text-align: left;
    position: absolute;
  }

  .weight-style {
    color: #000000;
    margin-left: 300px;
    font-size: 14px;
    margin-top: 4px;
    position: absolute;
  }

  .button-style-popup {
    width: 280px;
    height: 30px;
    position: absolute;
    background-color: #30a46c;
    border-radius: 14px;
    margin-left: 10px;
    box-shadow: 3px 3px #a1e3c3;
    background-image: linear-gradient(to bottom right, #46c98a, #30a46c);
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 10px; */
  }

  .addressList-outer-container {
    color: #000000;
    /* margin-left: 30; */
    font-size: 20;
    margin-top: 20;
    background-color: #ffffff;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: auto;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    top: 0;
    left: 0;
    align-items: center;
    /* justify-content: center; */
  }

  .web-view-pay-success {
    margin-left: 190px;
    margin-top: -10px;
  }

  .web-view-address-list {
    margin-left: 100px;
  }

  .form-field-style-search-addressList {
    width: 500px;
    height: 50px;
    padding-left: 50px;
    font-size: 12px;
    margin-top: 10px;
    border-top: #a1e3c3;
    border-left: #a1e3c3;
    border-right: #a1e3c3;
  }

  .subtitle-style-addressList {
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    margin-top: 5px;
    margin-left: 15px;
  }

  .subtitle-style-addressList-2 {
    color: #a7a9ab;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    margin-top: 15px;
    margin-left: 53px;
    font-weight: bold;
  }

  .addresslist-line-style-active {
    width: 500px;
    height: 2px;
    background-color: #30a46c;
    margin-left: 30px;
    margin-top: 17px;
  }

  .addresslist-line-style-inactive {
    width: 500px;
    height: 2px;
    background-color: #f0f2f4;
    margin-left: 50px;
    margin-top: 17px;
  }

  .text-highlighter {
    font-weight: bold;
    color: #000000;
  }

  .payment-failed-style {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    margin-bottom: -20px;
    background-color: #c40000;
    border-radius: 28px;
    padding: 8px;
    padding-top: 9px;
    color: #ffffff;
    margin-left: 120px;
    font-size: 30px;
    font-weight: bold;
    margin-left: 320px;
  }

  .payment-failed-button-align {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 220px;
  }

  .paysuccess-alignment {
    font-size: 12px;
    margin-left: 60px;
    margin-right: 60px;
  }

  .form-container-addresslist {
    width: 500px;
    height: 490px;
    background-color: #f5f7f9;
    margin-left: 30px;
    margin-top: 20px;
    border-radius: 14px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px #a1e3c3;
    padding-bottom: 2px;
    scroll-behavior: smooth;
    overflow-y: scroll;
  }

  .address-main-style {
    position: relative;
    align-items: start;
    color: #000000;
    font-size: 11px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 20px;
    padding-left: 10px;
  }

  .address-sub-style {
    position: relative;
    align-items: start;
    justify-content: left;
    color: #7a7878;
    font-size: 11px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 30px;
    margin-left: 20px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 15px;
  }

  .payment-label-style {
    position: relative;
    /* margin-bottom: -10px; */
    margin-top: 10px;
  }

  .addresslist-line-style {
    width: 450px;
    height: 2px;
    background-color: #8e9090;
    margin-left: 40px;
    margin-top: 17px;
  }

  .addresslist-line-style:hover {
    background-color: #0c0c0c;
  }

  .form-field-style-map {
    width: 500px;
    height: 35px;
    padding-left: 10px;
    margin-left: 50px;
    font-size: 13px;
    /* margin-top: 15px; */
  }

  .web-view-style-map-check {
    margin-left: 10px;
  }

  .rate-outer-container {
    position: absolute;
    width: 20vw;
    height: 50vh;
    border: 2px solid red;
    background-color: #d6dbe3;
    opacity: 0.5;
    /* margin-top: 750px; */
  }

  .history-outer-container {
    position: fixed;
    width: 140vw;
    height: 140vh;
    background-color: #d6dbe3;
    opacity: 0.5;
    margin-top: -170px;
  }

  .rate-outer-container-termandcon {
    position: absolute;
    width: auto;
    height: auto;
    background-color: #d6dbe3;
    /* opacity: 0.5; */
    /* margin-top: -750px; */
  }

  .goto-myorders {
    width: 160px;
    margin-left: 120px;
    margin-top: 140;
    margin-left: 100px;
  }

  .rate-inner-container-termandcon {
    /* border: 2px solid red; */
    width: auto;
    height: 70vh;
    background-color: #f5f7f9;
    position: absolute;
    /* margin-left: 550px; */
    margin-top: 50px;
    border-radius: 14px;
    box-shadow: 2px 2px 3px 2px #a1e3c3;
    overflow: scroll;
    margin-right: 10px;
  }

  .loader-style {
    height: 100px;
    margin-top: -450px;
    border-radius: 60px;
    position: absolute;
    width: 150px;
    margin-left: 570px;
    background-color: #f5f7f9;
    box-shadow: 2px 2px 3px 2px #a1e3c3;
  }

  .close-button {
    margin-top: 30px;
    margin-left: 340px;
    background-color: #a1e3c3;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    padding: 2px;
    cursor: pointer;
  }

  .rate-inner-container {
    width: 290px;
    height: 310px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 60px;
    border: 1px solid;
    margin-top: 180px;
    border-radius: 14px;
    box-shadow: 2px 2px 3px 2px #a1e3c3;
  }

  .history-inner-container {
    width: 500px;
    height: 530px;
    background-color: #ffffff;
    position: absolute;
    margin-left: 400px;
    margin-top: 10px;
    border-radius: 14px;
    box-shadow: 2px 2px 3px 2px #a1e3c3;
  }

  .inner-booking-id {
    font-size: 15px;
    font-weight: bold;
    margin-top: 30px;
    position: absolute;
    margin-left: 20px;
    color: #000000;
  }

  .inner-truck-icon {
    position: absolute;
    width: 22px;
    height: 22px;
    margin-left: -70px;
  }

  .inner-booking-status {
    font-size: 14px;
    margin-top: 16px;
    margin-left: 372px;
    color: #000000;
    text-align: left;
  }

  .pickup-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    margin-left: -60px;
  }

  .pickup-loc {
    position: absolute;
    margin-top: -15px;
    margin-left: 390px;
    text-align: left;
    font-size: 14px;
    width: 280px;
    color: #000000;
  }

  .dot-icon {
    position: absolute;
    width: 91px;
    height: 181px;
    margin-top: 20px;
    margin-left: -250px;
  }

  .drop-icon {
    position: absolute;
    width: 27px;
    height: 20px;
    margin-top: 110px;
    margin-left: -55px;
  }

  .drop-loc {
    text-align: left;
    margin-left: 390px;
    font-size: 14px;
    margin-top: 100px;
    position: absolute;
    width: 280px;
    color: #000000;
  }

  .close-button {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
    position: absolute;
    margin-left: 430px;
    color: #ffffff;
    width: 32px;
    height: 27px;
    background-color: #30a46c;
    border-radius: 20px;
    padding-top: 5px;
  }

  .inner-payment-status {
    font-size: 14px;
    margin-top: -26px;
    margin-left: 375px;
    color: #000000;
    text-align: left;
  }

  .trip-details {
    font-size: 15px;
    margin-top: 30px;
    margin-left: 20px;
    color: #000000;
    font-weight: bold;
    position: absolute;
  }

  .inner-weight {
    font-size: 14px;
    margin-top: 3px;
    margin-left: 20px;
    color: #000000;
    text-align: left;
    position: absolute;
    width: 90px;
  }

  .inner-money-icon {
    position: absolute;
    width: 21px;
    height: 21px;
    margin-left: -70px;
  }

  .pop-up-title-style {
    margin-top: 20px;
    color: #30a46c;
    font-size: 15px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
  }

  .pop-up-content-style {
    margin-top: 10px;
    color: #464343;
    font-size: 12px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  .button-style-popup {
    width: 200px;
    height: 30px;
    position: absolute;
    background-color: #30a46c;
    border-radius: 14px;
    margin-left: 40px;
    box-shadow: 3px 3px #a1e3c3;
    background-image: linear-gradient(to bottom right, #46c98a, #30a46c);
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    /* padding-top: 10px; */
  }

  .estimate-rate-style {
    width: 85px;
    height: 73px;
    position: absolute;
    background-color: #30a46c;
    border-radius: 70px;
    margin-left: 100px;
    box-shadow: 3px 3px #a1e3c3;
    background-image: linear-gradient(to bottom right, #46c98a, #30a46c);
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    padding-top: 10px;
  }

  .title-style {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 20px;
    margin-right: 40px;
    /* border: 1px solid green; */
  }

  .title-style-about {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 20px;
  }

  .description-style {
    color: gray;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
  }

  /* .form-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-top: 40px;
  } */

  .subtitle-style {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    line-height: 2rem;
  }

  .subtitle-style-heading {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    line-height: 1rem;
    /* font-weight: 600; */
  }

  .subtitle-style-bag {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    display: flex;
    line-height: 2rem;
    margin-left: 20px;
  }

  .subtitle {
    font-size: 15px;
    color: #000000;
    font-weight: 700;
  }

  .form-field-style {
    width: 200px;
    height: 25px;
    margin: 10px;
    padding-left: 10px;
  }

  .form-field-style-input {
    height: 28px;
    width: 180px;
    padding-left: 15px;
    padding-right: 10px;
    border-radius: 20px;
    border: 1px solid grey;
  }

  .form-field-style-vehicle-type {
    width: 180px;
    height: 25px;
    margin: 10px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: -55px;
  }

  .form-field-style-vehicle-type-2-whl {
    width: 30px;
    height: 20px;
    margin-top: 12px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: 2px;
    position: absolute;
  }

  .form-field-style-vehicle-type-3-whl {
    width: 30px;
    height: 20px;
    margin-top: 12px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: 14px;
    position: absolute;
  }

  .form-field-style-vehicle-type-4-whl {
    width: 30px;
    height: 20px;
    margin-top: 12px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: 20px;
    position: absolute;
  }

  .error-font-style {
    /* margin-top: -10px; */
    /* margin-left: 35px; */
    /* margin-bottom: -5px; */
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    position: absolute;

  }

  .error-font-style-mobile {
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
    margin-top: 5px;
    /* margin-right: 60px; */
  }

  .error-font-style-location {
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
    margin-top: 5px;
    /* margin-right: 60px; */
  }

  .error-font-style-bagId {
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
    margin-right: 60px;
  }

  .error-font-style-otp {
    font-size: 13px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    /* margin: 0%; */
  }

  .challan-error-style {
    margin-top: 10px !important;
    /* margin-left: 77px; */
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
  }

  .vehicle-error-style {
    /* border: 1px solid; */
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
  }

  .payment-error-style {
    margin-top: -3px;
    margin-left: -200px;
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
  }

  .terms-error-style {
    /* margin-top: -3px; */
    margin-left: -200px;
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
  }

  .bagid-error-style {
    margin-top: -3px;
    margin-left: -10px;
  }

  .sideBar {
    top: 0;
    left: 0;
    width: 300px;
    height: 750px;
    background-color: #f5f7f9;
    box-shadow: 5px 5px 5px 5px #a1e3c3;
    position: absolute;
  }

  .nav {
    display: flex;
    align-items: center;
    padding: 9rem 1rem 0 2rem;
  }

  .nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .nav a {
    color: inherit;
    color: #000000;
    text-decoration: none;
    width: "100%";
  }

  .nav li {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }

  .nav li.active {
    background-color: #a1e3c3;
    border-radius: 1rem;
  }

  .nav li:hover {
    background-color: #d6dbe3;
    border-radius: 1rem;
  }

  .side-title {
    font-size: 16px;
    font-weight: 600;
    font-family: sans-serif;
  }

  .app-header {
    display: none;
  }

  .sideBarStyle {
    display: none;
  }

  .privacy-policy-container {
    width: 885px;
    height: 500px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 400px;
    margin-top: 620px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
  }

  .pricing-policy-container {
    width: 700px;
    height: 250px;
    background-color: #f5f7f9;
    position: fixed;
    margin-left: 480px;
    margin-top: 450px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
    scroll-behavior: smooth;
  }
}

@media only screen and (max-width: 930px) {

  /* App.css */
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Align items at the start of the container */
    height: 100vh;
    /* margin-left: 20px; */
  }

  .welcome-message {
    /* margin-top: 0px;  */
    color: "#30A46C";
    /* Add margin to push the message to the top */
  }

  .center-container {
    text-align: center;
    margin-top: 50px;
  }

  .options {
    margin-top: 20px;
  }

  .option {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background-color: #30A46C;
    color: #fff;
    /* Button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 600;
    font-size: 16px;
  }

  .option:hover {
    background-color: #30A46C;
    /* Change the hover color */
    color: #fff;
    /* Change the text color on hover */
    border-radius: 50px;
  }

  .fade-in {
    animation: fadeIn 1s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .enquiry-text{
    color: #000;
    font-size: 4vw;
    margin: 10px;
    margin-top: 20px;
  }
  
  .redText {
    color: red;
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .app-header {
    background-color: #f5f7f9;
    height: 65px;
    width: 396px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    /* font-size: calc(10px + 2vmin); */
    /* color: rgb(241, 199, 199); */
    position: absolute;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
  }

  .App-inner-container {
    width: 340px;
    height: 50px;
    position: relative;
    background-color: #30a46c;
    border-radius: 14px;
    margin-top: 150px;
    margin-left: 35px;
    box-shadow: 3px 3px #a1e3c3;
    background-image: linear-gradient(to bottom right, #46c98a, #30a46c);
  }

  .App-semi-container {
    width: 0px;
    height: 0px;

    /* position: absolute; */
    /* background-color: #ffffff;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    align-items: center;
    justify-content: center; */
  }

  .form-container {
    width: 370px;
    height: 880px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 20px;
    margin-top: -50px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
  }

  .form-container-for-book-page {
    width: 370px;
    height: 680px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 15px;
    margin-top: 100px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
  }

  .form-contn-for-book-page {
    margin-top: -55px;
  }

  .day-field-align {
    margin-left: -30px;
    width: 120px;
    height: 25px;
    margin-top: 8px;
    position: absolute;
  }

  .day-field-align-same {
    margin-left: 18px;
    width: 30px;
    height: 20px;
    margin-top: 11px;
    position: absolute;
  }

  .day-field-align-next {
    margin-left: 16px;
    width: 30px;
    height: 20px;
    margin-top: 11px;
    position: absolute;
  }

  .day-field-label-same {
    width: 70px;
    margin-left: 33px;
    margin-top: 12px;
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    line-height: 1rem;
  }
  .day-field-label-same.delhi{
    width: 70px;
    margin-left: 25px;
    margin-top: 12px;
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    line-height: 1rem;
  }


  .day-field-label-next {
    width: 70px;
    margin-left: 33px;
    margin-top: 12px;
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    line-height: 1rem;
  }

  .delivery-immediate {
    font-size: 10px;
    color: #30A46C;
    padding: 5px;
    margin-left: 72px;
  }

  .delivery-6-hours {
    font-size: 10px;
    color: #30A46C;
    padding: 5px;
    margin-left: 28px;
  }

  .day-field-label {
    width: 70px;
    margin-left: -50px;
    margin-top: 12px;
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    line-height: 1rem;
  }

  .edit-icon-style {
    margin-top: -45px;
    margin-left: 220px;
    margin-bottom: 10px;
    padding: 10px;
    position: absolute;
  }

  .edit-lable-style {
    display: none;
  }

  .icon-style {
    width: 17px;
    height: 20px;
  }

  .web-view-style-map-check {
    margin-left: 10px;
  }

  .title-style {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 16px;
    /* border: 1px solid blue; */
  }

  .description-style {
    color: rgb(112, 111, 111);
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
  }

  .form-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-top: 70px;
  }

  .subtitle-style {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    line-height: 1rem;
    /* display:"flex";
    margin-left: 15px; */
  }

  .subtitle-style-heading {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    line-height: 1rem;
  }

  .web-view-style-time {
    margin-left: 75px;
    position: absolute;
    margin-top: -20px;
  }

  .web-view-style-time-from {
    margin-left: -30px;
    position: absolute;
    margin-top: 5px;
  }

  .web-view-style-time-to {
    margin-left: 10px;
    position: absolute;
    margin-top: 5px;
  }

  .web-view-style-challan {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .web-view-style-2-wheeler {
    width: 70px;
    margin-left: 5px;
    margin-top: 45px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-around;
  }

  .web-view-style-3-wheeler {
    width: 75px;
    margin-left: 10px;
    margin-top: 34px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .web-view-style-pickup {
    width: 80px;
    margin-left: 25px;
    margin-top: 43px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    /* Adjust the blur amount as needed */
    z-index: 999;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 200px;
  }

  .modal button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .subtitle {
    font-size: 12px;
    color: #000000;
    font-weight: 600;
  }

  .form-field-style {
    width: 180px;
    height: 25px;
    margin: 10px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: 80px;
  }

  .form-field-style-input {
    height: 28px;
    width: 180px;
    font-size: 11px;
    margin: 10px;
    padding-left: 15px;
    padding-right: 10px;
    margin-left: 20px;
    border-radius: 20px;
    border: 1px solid grey
  }

  .error-font-style {
    margin-left: -5px;
    /* margin-bottom: -5px; */
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    position: relative;
    margin-top: -8px;
  }

  .error-font-style-mobile {
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
    /* margin-left: 25px; */
  }

  .error-font-style-bagId {
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
    /* margin-right: 60px; */
  }

  .error-font-style-location {
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
    /* margin-left: 25px; */
  }

  .error-font-style-otp {
    font-size: 13px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    /* margin: 0%; */
    /* margin-top: 5px;  */
  }

  .challan-error-style {
    margin-top: 10px !important;
    /* margin-left: 77px; */
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
  }

  .vehicle-error-style {
    /* border: 1px solid; */
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
  }

  .payment-error-style {
    margin-top: 2px;
    margin-left: 30px;
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
  }

  .terms-error-style {
    margin-top: -3px;
    margin-left: -200px;
    font-size: 11px;
    color: red;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0%;
  }

  .bagid-error-style {
    margin-top: 2px;
    margin-left: 6px;
  }

  .shop-detail-style {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    font-weight: bold;
    color: #ffffff;
  }

  .shop-sub-detail-style {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    font-weight: normal;
    color: #fafafa;
  }

  .map-outer-container {
    color: #000000;
    margin-left: 30;
    font-size: 20;
    margin-top: 20;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    /* width: 130vw;
    height: 140vh; */
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    margin-top: 0px;
    top: 0;
    left: 0;
    align-items: center;
  }

  .map-container {
    width: 66vw;
    height: 550px;
    top: 0;
    left: -5px;
    position: absolute;
    scroll-behavior: smooth;
  }

  .form-display-map {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: -200px;
  }

  .form-field-style-search {
    width: 180px;
    height: 30px;
    padding-left: 10px;
    margin-left: -78px;
    font-size: 13px;
    margin-top: 87px;
  }

  .form-field-style-search-addressList {
    width: 338px;
    height: 50px;
    padding-left: 50px;
    font-size: 12px;
    margin-top: 10px;
    border-top: #a1e3c3;
    border-left: #a1e3c3;
    border-right: #a1e3c3;
  }

  .form-field-style-map {
    width: 250px;
    height: 35px;
    padding-left: 10px;
    margin-left: 18px;
    font-size: 13px;
    margin-top: 5px;
  }

  .form-container-map {
    width: 310px;
    height: 250px;
    background-color: #f5f7f9;
    /* margin-left: 50px; */
    position: fixed;
    margin-top: 340px;
    border-radius: 14px;
    box-shadow: 1px 1px 2px 3px #a1e3c3;
  }

  .subtitle-style-map {
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    /* margin-top: 5px; */
    margin-left: 7px;
  }

  .subtitle-style-bag {
    color: #30a46c;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    line-height: 2rem;
  }

  .addressList-outer-container {
    color: #000000;
    /* margin-left: 30; */
    font-size: 20;
    /* margin-top: 40; */
    background-color: #ffffff;
    position: absolute;
    display: flex;
    flex-direction: column;

    width: auto;
    height: auto;
    /* border-top-left-radius: 14px; */
    /* border-top-right-radius: 14px; */
    top: 40px;
    left: 0;
    align-items: start;
    /* justify-content: center; */
  }

  .addresslist-line-style-active {
    width: 317px;
    height: 2px;
    background-color: #30a46c;
    margin-left: 30px;
    margin-top: 17px;
  }

  .ReactModal__Overlay ReactModal__Overlay--after-open {
    border: 1px solid red;
    position: absolute;
    inset: 40px !important;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: scroll;
    border-radius: 10px;
    outline: none;
    padding: 20px;
    width: 80%;
    height: 80%;
    margin: auto;
  }

  .ReactModal__Overlay {
    inset: 0px !important;
  }

  .ReactModal__Overlay--after-open {
    inset: 0px !important;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: scroll !important;
    border-radius: 10px;
    outline: none;
    padding: 20px;
    width: 85%;
    height: 90%;
    margin: auto;
  }


  .ReactModal__Content--after-open {
    position: absolute;
    inset: 0px !important;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: scroll !important;
    border-radius: 10px;
    outline: none;
    padding: 20px;
    width: 80%;
    height: 80%;
    margin: auto;
  }

  .addresslist-line-style-inactive {
    width: 317px;
    height: 2px;
    background-color: #f0f2f4;
    margin-left: 50px;
    margin-top: 17px;
  }

  .subtitle-style-addressList {
    color: #3e4d62;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    margin-top: 5px;
    margin-left: 15px;
  }

  .subtitle-style-addressList-2 {
    color: #a7a9ab;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 11px;
    margin-top: 15px;
    margin-left: 53px;
    font-weight: bold;
  }

  .form-container-addresslist {
    width: 335px;
    height: 600px;
    background-color: #f5f7f9;
    margin-left: 30px;
    margin-top: 20px;
    border-radius: 14px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px #a1e3c3;
    padding-bottom: 2px;
    scroll-behavior: smooth;
    overflow-y: scroll;
  }

  .address-main-style {
    position: relative;
    align-items: start;
    color: #000000;
    font-size: 11px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 20px;
    padding-left: 10px;
  }

  .address-sub-style {
    position: relative;
    align-items: start;
    justify-content: left;
    color: #7a7878;
    font-size: 11px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 30px;
    margin-left: 20px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 15px;
  }

  .payment-type-alignment {
    margin-left: -60px;
    margin-top: 8px;
  }

  .payment-label-style {
    position: relative;
    margin-left: 50px;
  }

  .addresslist-line-style {
    width: 280px;
    height: 2px;
    background-color: #8e9090;
    margin-left: 40px;
    margin-top: 17px;
  }

  .addresslist-line-style:hover {
    background-color: #0c0c0c;
  }

  .rate-outer-container {
    position: fixed;
    border: 2px solid green;
    width: 140vw;
    height: 140vh;
    background-color: #d6dbe3;
    opacity: 0.5;
    margin-top: 180px;
  }

  /* .history-outer-container {
    position: relative;
    width: 90vw;
    height: 125vh;
    background-color: #d6dbe3;
    opacity: 0.5;
    margin-top: -180px;
  } */

  .rate-outer-container-termandcon {
    position: absolute;
    width: auto;
    height: auto;
    background-color: #d6dbe3;
    /* opacity: 0.5; */
    /* margin-top: -600px; */
  }

  .rate-inner-container-termandcon {
    width: 480px;
    height: 550px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 57px;
    /* margin-top: -100px; */
    border-radius: 14px;
    box-shadow: 2px 2px 3px 2px #a1e3c3;
    overflow: scroll;
  }

  .loader-style {
    height: 100px;
    margin-top: 100px;
    border-radius: 60px;
    width: 150px;
    margin-left: 130px;
    background-color: #f5f7f9;
    box-shadow: 2px 2px 3px 2px #a1e3c3;
  }

  .close-button {
    margin-top: 25px;
    margin-left: 225px;
    background-color: #a1e3c3;
    border-radius: 50px;
    width: 30px;
    height: 20px;
    padding-top: 1px;

    cursor: pointer;
  }

  .web-view-style-three {
    margin-left: 80px;
    margin-top: 10px;
  }

  .form-field-style-vehicle-type {
    width: 175px;
    height: 25px;
    margin: 10px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: -55px;
  }

  .form-field-style-vehicle-type-2-whl {
    width: 30px;
    height: 20px;
    margin-top: 10px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: 2px;
    position: absolute;
  }

  .form-field-style-vehicle-type-3-whl {
    width: 30px;
    height: 20px;
    margin-top: 10px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: 14px;
    position: absolute;
  }

  .form-field-style-vehicle-type-4-whl {
    width: 30px;
    height: 20px;
    margin-top: 10px;
    padding-left: 10px;
    font-size: 11px;
    margin-left: 20px;
    position: absolute;
  }

  .two-wheeler-img-style {
    width: 25px;
    height: 25px;
    margin-top: 8px;
    margin-bottom: -20px;
    margin-left: 40px;
    position: absolute;
  }

  .three-wheeler-img-style {
    width: 25px;
    height: 23px;
    margin-top: 10px;
    margin-bottom: -20px;
    margin-left: 50px;
    position: absolute;
  }

  .four-wheeler-img-style {
    width: 30px;
    height: 25px;
    margin-top: 10px;
    margin-bottom: -20px;
    margin-left: 55px;
    position: absolute;
  }

  .rate-inner-container {
    width: 280px;
    height: 310px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 57px;
    margin-top: 300px;
    border-radius: 14px;
    box-shadow: 2px 2px 3px 2px #a1e3c3;
  }

  .history-inner-container {
    width: 360px;
    height: 550px;
    background-color: #ffffff;
    position: absolute;
    margin-left: 20px;
    margin-top: 110px;
    border-radius: 14px;
    box-shadow: 2px 2px 3px 2px #a1e3c3;
  }

  .pop-up-title-style {
    margin-top: 20px;
    color: #30a46c;
    font-size: 15px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
  }

  .pop-up-content-style {
    margin-top: 10px;
    color: #464343;
    font-size: 12px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  .button-style-popup {
    width: 200px;
    height: 30px;
    position: absolute;
    background-color: #30a46c;
    border-radius: 14px;
    margin-left: 40px;
    box-shadow: 3px 3px #a1e3c3;
    background-image: linear-gradient(to bottom right, #46c98a, #30a46c);
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    /* padding-top: 10px; */
  }

  .button-style-ok {
    width: 120px;
    height: 25px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    margin-top: 35px;
    margin-right: 7px;
    margin-left: 90px;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    padding-top: 8px;
  }

  .estimate-rate-style {
    width: 85px;
    height: 73px;
    position: absolute;
    background-color: #30a46c;
    border-radius: 70px;
    margin-left: 100px;
    box-shadow: 3px 3px #a1e3c3;
    background-image: linear-gradient(to bottom right, #46c98a, #30a46c);
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    padding-top: 10px;
  }

  .button-style {
    width: 210px;
    height: 30px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    margin-top: 10px;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .button-style-main {
    width: 210px;
    height: 30px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    /* margin-top: 10px; */
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .button-style-disable {
    width: 180px;
    height: 40px;
    background-color: #83dbb0;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    /* margin-top: 90px; */
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .verify-button-disable {
    width: 120px;
    height: 30px;
    padding-top: 10px;
    margin-left: 110px;
    margin-top: 20px;
    background-color: #83dbb0;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .my-order-button {
    width: 85px;
    height: 25px;
    background-color: #30a46c;
    border-radius: 10px;
    padding-top: 8px;
    margin-top: 22px;
    margin-left: 263px;
    margin-right: -110px;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
  }

  .my-order-button-shop {
    width: 85px;
    height: 25px;
    background-color: #30a46c;
    border-radius: 10px;
    padding-top: 8px;
    margin-top: 10px;
    margin-left: 263px;
    margin-right: -110px;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
  }

  .goto-myorders {
    width: 160px;
    margin-left: 120px;
    margin-top: 140;
  }

  .text-highlighter {
    font-weight: bold;
    color: #000000;
  }

  .payment-failed-style {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    margin-bottom: -20px;
    background-color: #c40000;
    border-radius: 28px;
    padding: 8px;
    padding-top: 9px;
    color: #ffffff;
    margin-left: 120px;
    font-size: 30px;
    font-weight: bold;
  }

  .track-page-outer-container {
    /* position: absolute; */
    background-color: #f8faf9;
    opacity: 50;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200vw;
    height: 300vh;
  }

  .track-page-inner-container {
    position: absolute;
    background-color: #ffffff;
    border-radius: 10px;
    opacity: 100;
    width: 200vw;
    height: 300vh;
  }

  .ball {
    animation: bounce 0.5s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
    animation-iteration-count: infinite;
  }

  .sideBarStyle {
    top: 0;
    left: 0;
    width: 260px;
    height: 900px;
    background-color: #f5f7f9;
    box-shadow: 2px 2px 3px 3px #a1e3c3;
    position: fixed;
  }

  .navbar {
    display: flex;
    align-items: center;
    padding: 6rem 1rem 0 2rem;
  }

  .navbar ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .navbar a {
    color: inherit;
    color: #000000;
    text-decoration: none;
    width: "100%";
  }

  .navbar li {
    padding: 0.7rem 0.5rem 1rem 0.5rem;
  }

  .navbar li.active {
    background-color: #a1e3c3;
    border-radius: 1rem;
  }

  .navbar li:hover {
    background-color: #d6dbe3;
    border-radius: 1rem;
  }

  .side-title-style {
    font-size: 13px;
    font-weight: 600;
    font-family: sans-serif;
  }

  .sideBar {
    display: none;
  }

  .privacy-policy-container {
    width: 300px;
    height: 550px;
    background-color: #f5f7f9;
    position: fixed;
    margin-left: 60px;
    margin-top: 150px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
    scroll-behavior: smooth;
  }

  .pricing-policy-container {
    width: 300px;
    height: 200px;
    background-color: #f5f7f9;
    position: fixed;
    margin-left: 55px;
    margin-top: 150px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
    scroll-behavior: smooth;
  }

  .otp-verification-container {
    width: 345px;
    height: 520px;
    background-color: #f5f7f9;
    position: absolute;
    margin-left: 25px;
    margin-top: 140px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
    scroll-behavior: smooth;
  }

  .order-history-container {
    width: 400px;
    height: 650px;
    background-color: #f5f7f9;
    position: absolute;
    /* margin-left: 16px; */
    margin-top: 78px !important;
    /* align-items: center;
    justify-content: center; */
    overflow: scroll;
    scroll-behavior: smooth;
  }

  .list-container {
    width: 390px;
    height: 120px;
    background-color: #ffffff;
    position: relative;
    margin-left: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    overflow: scroll;
    /* Add this line to enable scrolling */
    scroll-behavior: smooth;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
    border-radius: 14px;
  }

  .verify-button {
    width: 120px;
    height: 30px;
    padding-top: 10px;
    margin-left: 120px;
    margin-top: 20px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .verify-button-order {
    width: 160px;
    height: 30px;
    padding-top: 10px;
    margin-left: 90px;
    margin-top: 120px;
    background-color: #30a46c;
    border-radius: 14px;
    color: #ffffff;
    font-size: medium;
    cursor: pointer;
    box-shadow: 3px 3px #a1e3c3;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 600;
  }

  .booking-id-style {
    font-size: 14px;
    font-weight: bold;
    margin-top: 17px;
    position: absolute;
    margin-left: 20px;
  }

  .time-text-style {
    color: #000000;
    margin-left: 140px;
    font-size: 14px;
    margin-top: 16px;
  }

  .trip-status {
    color: #000000;
    margin-left: 400px;
    font-size: 14px;
    margin-top: 16px;
    text-align: left;
  }

  .weight-style {
    color: #000000;
    margin-left: 190px;
    font-size: 14px;
    margin-top: 4px;
    position: absolute;
  }

  .payment-status {
    position: absolute;
    color: #000000;
    margin-left: 220px;
    font-size: 14px;
    margin-top: -20px;
    text-align: left;
  }

  .inner-booking-id {
    font-size: 15px;
    font-weight: bold;
    margin-top: 30px;
    position: absolute;
    margin-left: 20px;
    color: #000000;
  }

  .inner-booking-status {
    font-size: 14px;
    margin-top: 16px;
    margin-left: 368px;
    color: #000000;
    text-align: left;
  }

  .dot-icon {
    position: absolute;
    width: 91px;
    height: 181px;
    margin-top: 20px;
    margin-left: -197px;
  }

  .drop-icon {
    position: absolute;
    width: 24px;
    height: 18px;
    margin-top: 110px;
  }

  .pickup-loc {
    position: absolute;
    margin-top: -15px;
    margin-left: 380px;
    text-align: left;
    font-size: 14px;
    width: 280px;
    color: #000000;
  }

  .drop-loc {
    text-align: left;
    margin-left: 380px;
    font-size: 14px;
    margin-top: 100px;
    position: absolute;
    width: 280px;
    color: #000000;
  }

  .pickup-icon {
    position: absolute;
    width: 21px;
    height: 21px;
    margin-top: -10px;
  }

  .trip-details {
    font-size: 15px;
    margin-top: 30px;
    margin-left: 20px;
    color: #000000;
    font-weight: bold;
    position: absolute;
  }

  .inner-weight {
    font-size: 14px;
    margin-top: 3px;
    margin-left: 20px;
    color: #000000;
    text-align: left;
    position: absolute;
    width: 90px;
  }

  .close-button {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    position: absolute;
    margin-left: 300px;
    color: #ffffff;
    width: 32px;
    height: 27px;
    background-color: #30a46c;
    border-radius: 20px;
    padding-top: 5px;
  }

  .inner-truck-icon {
    position: absolute;
    width: 22px;
    height: 22px;
  }

  .inner-money-icon {
    position: absolute;
    width: 21px;
    height: 21px;
    margin-left: 2px;
  }

  .inner-payment-status {
    font-size: 14px;
    margin-top: -26px;
    margin-left: 370px;
    color: #000000;
    text-align: left;
  }

  .about-us-container {
    width: 300px;
    height: fit-content;
    background-color: #f5f7f9;
    position: fixed;
    margin-left: 35px;
    margin-top: 80px;
    padding-bottom: 40px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 4px 5px 5px #a1e3c3;
  }

  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(0, 20px, 0);
    }
  }

  /* Prefix Support */

  @-webkit-keyframes bounce {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    to {
      -webkit-transform: translate3d(0, 200px, 0);
      transform: translate3d(0, 200px, 0);
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}